import { SelectionChangedEvent } from "ag-grid-community";
import { ParameterDetailEntity } from "domain/entity/Parameter/ParameterDetailEntity";
import { INITIAL_PARAMETER_DETAIL_COL_DEF,transferRowData } from "presentation/constant/Parameter/ParameterDetailColumnDefinition";
import { ParameterMaintenanceConstant } from "presentation/constant/Parameter/ParameterMaintenanceConstant";
import { useParameterMaintenanceVM } from "presentation/hook/Parameter/useParameterMaintenanceVM";
import { useParameterMaintenanceTracked } from "presentation/store/Parameter/ParameterMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";

const ParameterDetailTablePanel:React.FC = () => {
    const [paramMainState] = useParameterMaintenanceTracked();
    const paramMainVM = useParameterMaintenanceVM();
    const {detailState} = paramMainState;
    const {parameterDtlList} = detailState;

    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    let gridRef: any = useRef();

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_PARAMETER_DETAIL_COL_DEF.slice());
            
        gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        paramMainVM.updateSelectedParamDtlRows(selectedRows);
      }, [paramMainVM])

    const handleRowDoubleClick = useCallback((paramDtl: ParameterDetailEntity) => {
        paramMainVM.onParamDtlRowClick(paramDtl);
    }, [paramMainVM])

    const handleAddClick = useCallback(async() => {
        paramMainVM.onParamDtlAdd();      
    },[paramMainVM])

    const memoParamDtlTable = useMemo(() => {
        return <HPHTable
            id='param-dtl-table'
            headerLabel={ParameterMaintenanceConstant.Detail.TITLE}            
            columns={INITIAL_PARAMETER_DETAIL_COL_DEF.slice()}
            data={transferRowData(parameterDtlList)??[]}
            //headerActionButtons={tbBtns}
            showPaginator={false}
            editable={false}
            /*showAddIcon={allowCreate && isAdd && !isCompAdd && !isCompEdit}
            showUploadIcon={!(isEditable && isRead) && !isCompAdd && !isCompEdit}*/
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            rowSelection={"multiple"}
            onAddClick={handleAddClick}
            onSelectionChanged={handleSelectionChange}            
            onRowDoubleClick={(e: any) => handleRowDoubleClick(e.data)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)"
            ref={gridRef} />;
    }, [parameterDtlList, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    return <>
    { <TableWrapper>{memoParamDtlTable}</TableWrapper>}
    </>;
}

export default memo(ParameterDetailTablePanel);