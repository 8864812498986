
export const ParameterMaintenanceConstant = {    
    TITLE: "Parameter",
    OPERATING_COMPANY: "Operating Company",
    OPERATING_UNIT: "Operating Unit",
    PARAMETER_CODE: "Parameter Code",
    PARAMETER_DESC: "Parameter Desc.",
    ACTIVE_IND: 'Active Ind.',

    Detail: {
        TITLE: "Parameter Detail",
        SUB_TITLE: "Parameter Sub Detail",
        PARAMETER_DETAIL_CODE: "Parameter Detail Code",
        PARAMETER_DETAIL_DESC: "Parameter Detail Desc."
    }
}
