import { ParameterMaintenanceConstant } from "presentation/constant/Parameter/ParameterMaintenanceConstant"
import { useParameterMaintenanceVM } from "presentation/hook/Parameter/useParameterMaintenanceVM"
import { useParameterMaintenanceTracked } from "presentation/store/Parameter/ParameterMaintenanceProvider"
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer"
import { NbisCommonField } from "presentation/view/components/NbisCommonField"
import { useCallback, useMemo, useState } from "react"
import { FieldType, HPHButton, HPHCheckbox, IFieldValue, Loader } from "veronica-ui-component/dist/component/core"
import { SidebarCaption, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled"

export const ParameterDetailPanel = () => {
    const [paramMainState] = useParameterMaintenanceTracked();
    const paramMainVM = useParameterMaintenanceVM();
    //const messageBarVM = useMessageBarVM();
    const { detailState} = paramMainState;
    const { isAdd, isEditable, isRead, isSaveClicked, allFormState} = detailState;
    const currentParamDtl = detailState.currentParamDtlEntity;
    const PARAM_MAIN_CONSTANT = ParameterMaintenanceConstant;
    const [isLoading] = useState(false);
    
    const handleCancelClick = () => {
        paramMainVM.onParamDtlCancel();
    }

    const handleSaveClick = useCallback(async ()  => {
        /*if(!_.isEmpty(parameterDtlList) && parameterDtlList.find(e=> e.tariffCompCode===currentParamDtl.tariffCompCode)){
            messageBarVM.showWarining('Tariff Component already exists.');
            return;
        }

        setIsLoading(true);
        tariffCodeVM.onComponentSaveClicked();
        const res = await tariffCodeVM.onTempSaveDetail(currentTarCodeComp);
        setIsLoading(false);
        if(res){
            messageBarVM.showWarining(res["mandatoryCheckFail"]);
        }*/
    },[])

    const memoParamDtlCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"330px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                readOnlyValue={currentParamDtl?.parameterDtlCode??''}
                fieldValue={currentParamDtl?.parameterDtlCode??''}
                fieldLabel={PARAM_MAIN_CONSTANT.Detail.PARAMETER_DETAIL_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'parameterDtlCode'}                
                maxLength={60}
                isShowMissingError={true}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    paramMainVM.onParamDtlFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [PARAM_MAIN_CONSTANT.Detail.PARAMETER_DETAIL_CODE, allFormState, currentParamDtl?.parameterDtlCode, isAdd, isSaveClicked, paramMainVM])

    const memoParamDtlDesc = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"330px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentParamDtl?.parameterDtlDesc??''}
                fieldValue={currentParamDtl?.parameterDtlDesc??''}
                fieldLabel={PARAM_MAIN_CONSTANT.Detail.PARAMETER_DETAIL_DESC}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'parameterDtlDesc'}                
                maxLength={60}
                isShowMissingError={true}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    paramMainVM.onParamDtlFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [PARAM_MAIN_CONSTANT.Detail.PARAMETER_DETAIL_DESC, allFormState, currentParamDtl?.parameterDtlDesc, isRead, isSaveClicked, paramMainVM])

    const memoActiveInd = useMemo(() =>
        <>
          <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>

            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={[]}
                fieldLabel={PARAM_MAIN_CONSTANT.ACTIVE_IND} 
                fieldType={FieldType.TEXT} 
                isReadOnly={true}
                onFieldChange={()=>{}}/>
            <HPHCheckbox
                label={""}
                checked={currentParamDtl?.activeInd==='Y'}
                disabled={isRead}
                onChange={(e) => paramMainVM.onParamDtlCheckboxChange(e.checked, "activeInd")}
            />
        </div>
        </>
    , [PARAM_MAIN_CONSTANT.ACTIVE_IND, currentParamDtl?.activeInd, isRead, paramMainVM])

    return (
        <div className='side-form-content-wrapper' style={{position:'relative', height:"100vh-128px"}}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>
                            {/*<SidebarTitle>{isAdd?'NEW TARIFF COMPONENT': currentTarCodeComp.tariffCompCode + ' - ' + currentTarCodeComp.tariffCompValueCode}</SidebarTitle>*/}
                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
           
            <div className={'add-edit-form'} style={{ height:'50vh', maxHeight:'50vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoParamDtlCode}                            
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoParamDtlDesc}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoActiveInd}                            
                        </CriteriaItemContainer>
                    </div>
                </div>
                <div className='im-charge-data-search-criteria-buttons' >
                    {
                        <>
                            <HPHButton label={isRead?'Close':'Cancel'} size={'Small'} theme={'Secondary'} onClick={handleCancelClick} />
                            {!isRead&&<HPHButton label={isAdd?'Add':"Update"} size={'Small'} theme={'Primary'} onClick={()=>handleSaveClick()} />}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

