import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useMemo } from "react";
import { FieldType, HPHCheckbox, HPHInputTextarea } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import ParameterEditPanelTitleBar from "../ParameterEditPanelTitleBar";
import { useParameterMaintenanceTracked } from "presentation/store/Parameter/ParameterMaintenanceProvider";
import { useParameterMaintenanceVM } from "presentation/hook/Parameter/useParameterMaintenanceVM";
import { ParameterMaintenanceConstant } from "presentation/constant/Parameter/ParameterMaintenanceConstant";

export const ParameterHeaderFormPanel = () => {
    const [paramMainState] = useParameterMaintenanceTracked();
    const paramMainVM = useParameterMaintenanceVM();
    const {currentSelectedRow, masterState} = paramMainState;
    const {updateParamEntity, isAdd, isEditable, isRead, isSaveClicked} = masterState;
    //const {parameterDtlList} = detailState;
    const PARAM_MAIN_CONSTANT = ParameterMaintenanceConstant;
    
    /*const [anainfoState] = useANAInfoTracked();
    const {allowUpdate} = anainfoState;*/

    const currentEntity = isRead? currentSelectedRow : updateParamEntity;
    
    const memoParamCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"400px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentEntity?.parameterCode??''}                
                fieldLabel={PARAM_MAIN_CONSTANT.PARAMETER_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'parameterCode'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />        
        </div>
    , [PARAM_MAIN_CONSTANT.PARAMETER_CODE, currentEntity?.parameterCode, isSaveClicked])

    const memoParamDesc = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"600px", marginBottom:"24px"}}>            
            {/*<NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.parameterDesc??''}
                fieldValue={currentEntity?.parameterDesc??''}
                fieldLabel={PARAM_MAIN_CONSTANT.PARAMETER_DESC}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'parameterDesc'}
                isShowMissingError={false}
                maxLength={500}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />*/}
            <HPHInputTextarea
                label={PARAM_MAIN_CONSTANT.PARAMETER_DESC}
                width="600px"
                rows={3}
                disabled={isRead}
                resizable={false}
                helpIcon={false}                
                value={currentEntity?.parameterDesc??''}
                onChange={() =>{}} />
        </div>
    , [PARAM_MAIN_CONSTANT.PARAMETER_DESC, currentEntity?.parameterDesc, isRead])

    const memoActiveInd = useMemo(() =>
        <>
          <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={[]}
                fieldLabel={PARAM_MAIN_CONSTANT.ACTIVE_IND} 
                fieldType={FieldType.TEXT} 
                isReadOnly={true}
                onFieldChange={()=>{}}/>
            <HPHCheckbox
                label={""}
                checked={currentEntity?.activeInd==='Y'}
                disabled={isRead}
                onChange={(e) => paramMainVM.onCheckboxChange(e.checked, "activeInd")}
            />
        </div>
        </>
    , [PARAM_MAIN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isRead, paramMainVM])

    const memoEditPanelTitleBar = useMemo(() => {
        return <ParameterEditPanelTitleBar/>
    }, [])


    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}

        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'}>
                            <div className={'flex-row-item-stretch'}>
                                {<SidebarTitle>{currentEntity?.parameterCode??""}</SidebarTitle>}

                                {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </div>
                        </div>
                        
                        <CriteriaItemContainer>
                            {memoParamCode}
                            {memoActiveInd}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoParamDesc}                            
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}
