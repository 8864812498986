import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { ParameterMaintenanceConstant } from "./ParameterMaintenanceConstant";

const SCREEN_CONSTANT = ParameterMaintenanceConstant;
let dateFieldList: string[] = [];
export const INITIAL_PARAMETER_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.OPERATING_COMPANY,
        field: "operatingCompany",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.OPERATING_UNIT,
        field: "operatingUnit",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.PARAMETER_CODE,
        field: "parameterCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 380,
    },
    {
        headerName: SCREEN_CONSTANT.PARAMETER_DESC,
        field: "parameterDesc",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 650,
    },
    {
        headerName: SCREEN_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
    },
].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: { [key: string]: string }, fieldName: string) => {}) } = {};

    return defineColumn(col, index, dateFieldList, [], [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, [], [], externalFnctions);
}
