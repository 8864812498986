import { ParameterMaintenanceConstant } from "presentation/constant/Parameter/ParameterMaintenanceConstant";
import { useParameterMaintenanceVM } from "presentation/hook/Parameter/useParameterMaintenanceVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useParameterMaintenanceTracked } from "presentation/store/Parameter/ParameterMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const ParameterEditPanelTitleBar: React.FC = () => {
    const [paramMainState] = useParameterMaintenanceTracked();
    const paramMainVM = useParameterMaintenanceVM();
    //const messageBarVM = useMessageBarVM();
    const {currentSelectedRow, masterState} = paramMainState;
    const {updateParamEntity, isAdd, isEditable, isRead} = masterState;
    
    //const {isAdd: isCompAdd, isEditable: isCompEdit} = detailState;
    const [anainfoState] = useANAInfoTracked();
    const {allowCreate, allowUpdate} = anainfoState;
    const [isLoading] = useState(false);
    const currentEntity = isRead? currentSelectedRow : updateParamEntity;

    
    const isDisable = () => {
        /*if (isCompAdd || (!isCompAdd && isCompEdit)) return true;

        return false;*/
        return false;
    }

    const handleReset = useCallback(async () => {
        paramMainVM.onResetClick();
    }, [paramMainVM]);

    const handleEdit = useCallback(()=>{
        paramMainVM.onEdit(currentEntity);
    },[currentEntity, paramMainVM])

    const handleSave = useCallback(async () => {
        /*setIsLoading(true);
        try {
            paramMainVM.onSaveClicked();
            const res = await paramMainVM.onSave(currentEntity,isAdd);
            if(res && res["mandatoryCheckFail"]){
                messageBarVM.showError(res["mandatoryCheckFail"]?.toString());
            }else if(res){
                messageBarVM.showError(res);
            }
        } catch (error) {

        }finally{
            setIsLoading(false)
        }*/
        
    },[]);

    return <Sidebarheader style={{width: '100%'}}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{title: ParameterMaintenanceConstant.TITLE},{ title: ParameterMaintenanceConstant.TITLE }]} onCurrentClick={()=>{}}></HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {(!isAdd && (isEditable && isRead)) &&
                <>
                    {/* Edit Button */}
                    {
                        (
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                    }
                    {/* Close Button */}
                    <IconButton fileName='Icon-cross' size='medium' toolTipText={'Close'} toolTipArrow={false} onClick={paramMainVM.onCloseClick} />
                </>}
            {(isAdd || (isEditable && !isRead)) &&
                <>
                    {/* Reset Form Button */}
                    <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />
                    {/* Close Button */}
                    <IconButton fileName="Icon-cross" size="medium" disabled={isDisable()} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={paramMainVM.onCloseClick} />
                    {/* Save Button */}
                    {
                        (
                            (isAdd && allowCreate) ||
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName="Icon-tick" size="medium" disabled={isDisable()} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                    }
                </>}
        </StyledAction>
    </Sidebarheader>
}

export default memo(ParameterEditPanelTitleBar);