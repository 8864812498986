import { ParameterMaintenanceConstant } from "presentation/constant/Parameter/ParameterMaintenanceConstant";
import { useParameterMaintenanceTracked } from "presentation/store/Parameter/ParameterMaintenanceProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const ParameterTitleBar:React.FC = () => {
    const [paramMainState] = useParameterMaintenanceTracked();
    /*const paramMainVM = useParameterMaintenanceVM();
    const messageBarVM = useMessageBarVM();    
    //const {updatedRows} = paramMainState;*/
    const PARAM_MAIN_CONSTANT = ParameterMaintenanceConstant;
    const [ isLoading ] = useState<boolean>(false);

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{PARAM_MAIN_CONSTANT.TITLE}</HeaderTitle>
        {(paramMainState.isLoading || isLoading) && <Loader Indicator="Spinner" size="Medium" /> }       
        
    </Sidebarheader>
}

export default memo(ParameterTitleBar);