export interface ParameterDetailEntity {
    key: string,
    versionIdentifier: {
        version?: string
    },
    parentDtlId?: number | null,
    parameterId: number,
    parameterDtlCode: string,
    parameterDtlDesc?: string | null,
    activeInd: "Y" | "N",

    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_PARAMETER_DETAIL_ENTITY : ParameterDetailEntity = {
    key: "",
    versionIdentifier: {},
    parentDtlId: null,
    parameterId: 0,
    parameterDtlCode: "",
    parameterDtlDesc: null,
    activeInd: "Y",
}