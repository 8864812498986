import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";
import { ParameterMaintenanceModel } from "presentation/model/Parameter/ParameterMaintenanceModel";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import { ParameterRepository } from "domain/repository/parameter/ParameterRepo";
import { EMPTY_PARAMETER_ENTITY, ParameterEntity } from "domain/entity/Parameter/ParameterEntity";
import { EMPTY_PARAMETER_DETAIL_ENTITY, ParameterDetailEntity } from "domain/entity/Parameter/ParameterDetailEntity";
import _ from "lodash";

interface ParameterMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ParameterMaintenanceModel>> | ((value: SetStateAction<ParameterMaintenanceModel>) => void),
    ],
    repo: ParameterRepository,
    parameterDetailRepo: ParameterDetailRepository,
}

export const ParameterMaintenanceVM = ({dispatch,repo,parameterDetailRepo}:ParameterMaintenanceVMProps) => {
    const [parameterMainDispatch] = dispatch;
    
    const onShowLoading = () => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const updateSelectedRows = async (allRows:ParameterEntity[], rows:ParameterEntity[]) => {

        parameterMainDispatch(prevState => {             
            return {
                ...prevState,
                tableData: [...allRows],
                selectedRows: rows,               
            }
        })
    }    

    const onRowDoubleClick = (entity: ParameterEntity) => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: false,
                isShowDetail: true,
                currentSelectedRow: {
                    ...entity
                },   
                masterState:{
                    ...prevState.masterState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: true,
                    isSaveClicked: false,
                    isSliderOpen: true,
                    allFormState: {}
                },
                detailState:{
                    ...prevState.detailState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: false,
                    isSliderOpen: false,
                    isSelected: false,
                    allFormState: {}
                },
                subDetailState:{
                    ...prevState.subDetailState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: false,
                    isSliderOpen: false,
                    isSelected: false,
                    allFormState: {}
                }
            }
        })
    }    
    
    const loadDropdownOption = async () => {
        
    }

    const searchParameter = async() => {        
        await repo.getEntities().then((data) => {
            parameterMainDispatch(prevState => {
                return {
                    ...prevState,
                    tableData: data,
                    selectedRows: [],
                    currentSelectedRow: EMPTY_PARAMETER_ENTITY,
                    isBackMaster: false,
                }
            })
        }).catch((error) => {
            return [];
        })
    }

    const onAddClick = () => {
        parameterMainDispatch(prevState => ({
            ...prevState,
            
            detailState:{                    
                ...prevState.detailState,
                isTabularDataActive: false,
                isAdd: true,
                isRead: false,
                isEditable: false,
                isSliderOpen: true,
                isSelected: false,
                allFormState: {},

                updateParamDtlEntity: EMPTY_PARAMETER_DETAIL_ENTITY
            }
        }))
    };

    const onResetClick = async () => {
        /*tariffCodeDispatch(prevState => {
            let tempTarCodeEty: StandardTariffCodeEntity = EMPTY_STANDARD_TARIFF_CODE_ENTITY;
            if(!prevState.masterState.isAdd){
                tempTarCodeEty = prevState.currentSelectedRow
            }

            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    editingTariffCodeEntity: {
                        ...tempTarCodeEty,
                    },
                }
            }
        })*/
    }    

    const onSave =  async(currentParamEty: ParameterEntity, isAdd: boolean) => {
        /*const valHdrResult = await Validation(isAdd?createTariffCodeValidationSchema:updateTariffCodeValidationSchema).ValidateFormOnly(currentTarCodeEty);
        
        if (valHdrResult ) {
            let validatedTariffResult: {[x: string]: string} = {};  
            if (valHdrResult) {  
                validatedTariffResult = { ...validatedTariffResult, ...valHdrResult, mandatoryCheckFail: 'Please input the missing value.' };  
            }  



            tariffCodeDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedTariffResult
                        },

                    }                        
                }
            });
            return validatedTariffResult;
        } else {

            let res = null;
            if(isAdd){
                res = await standardTariffCodeRepo.createNewStandardTariffCode(currentTarCodeEty);
            }else{
                res = await standardTariffCodeRepo.updateStandardTariffCode(currentTarCodeEty);
            }
            
            if(res.success){    
                onCloseClick();
            }else{
                return res.data;
            }
        }*/
    }

    const onCloseClick = () => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: false,
                isBackFromDetail:true,
                selectedRows:[],
                currentSelectedRow: {...EMPTY_PARAMETER_ENTITY},
                masterState:{
                    ...prevState.masterState,
                    isAdd:false,
                    isEditable:false,
                    isRead: true,
                    isTabularDataActive: true,
                    editingTariffCodeEntity: EMPTY_PARAMETER_ENTITY,
                },
                detailState:{
                    ...prevState.detailState,
                    isAdd:false,
                    isEditable:false,
                    isRead: true,
                    isTabularDataActive: true,
                    updateParamDtlEntity: EMPTY_PARAMETER_DETAIL_ENTITY
                },
                subDetailState:{
                    ...prevState.subDetailState,
                    isAdd:false,
                    isEditable:false,
                    isRead: true,
                    isTabularDataActive: true,
                    updateParamSubDtlEntity: EMPTY_PARAMETER_DETAIL_ENTITY
                }
            }
        }); 
    }

    const onSaveClicked =() => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    
    const onEdit = (currentParamEty: ParameterEntity)=>{
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isRead: false,
                    isEditable: true, 
                    
                    updateParamEntity:{
                        ...currentParamEty,
                    }
                },
            }
        })
    }

    const onParamDtlCancel = () => {
        /*parameterMainDispatch(prevState => {
            return {
                ...prevState,
                detailState:{
                    ...prevState.detailState,
                    currentSelectItem: EMPTY_TARIFF_CODE_COMP_ENTITY,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    isSliderOpen: false,
                    allFormState:{}
                }
            }
        })*/
    }

    const onComponentSaveClicked =() => {
        /*tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                componentState: {
                    ...prevState.componentState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })*/
    }

    const onParamDtlFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }


        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                detailState:{
                    ...prevState.detailState,
                    currentParamDtlEntity: {
                        ...prevState.detailState.currentParamDtlEntity,
                        [fieldKey]: val
                    },
                    allFormState:{
                        ...prevState.detailState.allFormState,
                        [fieldKey]: '',
                    },
                }
            }
        })
    }

    const updateSelectedParamDtlRows = async (rows: any[]) => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                detailState:{
                    ...prevState.detailState,
                    selectedRows: rows,
                }
            }
        })
    }

    const onParamDtlRowClick = (row: ParameterDetailEntity) => {
        parameterMainDispatch(prevState => {
            return {
                ...prevState,
                detailState:{
                    ...prevState.detailState,
                    currentParamDtlEntity: row,
                    isSliderOpen:true,
                    isRead: !prevState.masterState.isAdd, 
                    isEditable: prevState.masterState.isAdd,
                }                       
            }
        });
    }

    const onParamDtlAdd = () => {
        parameterMainDispatch(prevState => {
            
            return {
                ...prevState,
                detailState:{
                    ...prevState.detailState,  
                    /*currentSelectItem: {
                        ...EMPTY_TARIFF_CODE_COMP_ENTITY,
                        tariffCompCode: defaultCompCode,
                        tariffCompValueCode: ''
                    }, */  
                    isAdd:true,
                    isSliderOpen: true,
                    isEditable:false,
                    isRead: false,
                    isSaveClicked: false,
                }
            }
        })
    }    

    const onSearchParamDtl = async (parameterCode: string) => {
        await parameterDetailRepo.getAllParameterDtlsByParameterCode(parameterCode).then((data) => {
            parameterMainDispatch(prevState =>({
                ...prevState,
                detailState:{
                    ...prevState.detailState,
                    parameterDtlList: data,
                    selectedRows: [],
                    updateParamDtlEntity: EMPTY_PARAMETER_DETAIL_ENTITY
                }
            }))
        }).catch((error) => {
            return [];
        })
    }

    /*const onComponentChanged = (componentCode: string | null) => {
        tariffCodeDispatch(prevState => {
            const valCode = !!!componentCode ? '' : prevState.dynamicOptions.tariffComponentValueDropdownOptions[componentCode][0].value;
            return {
                ...prevState,
                componentState:{
                    ...prevState.componentState,
                    currentSelectItem: {
                        ...prevState.componentState.currentSelectItem,
                        tariffCompValueCode: valCode,
                    }
                }
            }
        })
    }*/

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        parameterMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                updateParamEntity: {
                    ...prevState.masterState.updateParamEntity,
                    [fieldName]: checked ? "Y" : "N",
                }
            }
        }))
    }

    const onParamDtlCheckboxChange = (checked: boolean, fieldName: string) => {
        parameterMainDispatch(prevState => ({
            ...prevState,
            detailState: {
                ...prevState.detailState,
                currentParamDtlEntity: {
                    ...prevState.detailState.currentParamDtlEntity,
                    [fieldName]: checked ? "Y" : "N",
                }
            }
        }))
    }

        

    return {
        loadDropdownOption: loadDropdownOption,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onRowDoubleClick: onRowDoubleClick,        
        updateSelectedRows: updateSelectedRows,        
        onAddClick:onAddClick,        
        onResetClick: onResetClick,        
        onSave: onSave,        
        onCloseClick: onCloseClick,
        onSaveClicked: onSaveClicked,
        onEdit: onEdit,        
        onParamDtlCancel: onParamDtlCancel,
        onComponentSaveClicked: onComponentSaveClicked,
        onParamDtlFieldChange: onParamDtlFieldChange,
        searchParameter: searchParameter,
        onSearchParamDtl: onSearchParamDtl,  
        updateSelectedParamDtlRows: updateSelectedParamDtlRows,
        onParamDtlRowClick: onParamDtlRowClick,
        onParamDtlAdd: onParamDtlAdd,
        onCheckboxChange: onCheckboxChange,
        onParamDtlCheckboxChange: onParamDtlCheckboxChange
    }
}