import { memo, useEffect } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import { useParameterMaintenanceTracked } from "presentation/store/Parameter/ParameterMaintenanceProvider";
import { useParameterMaintenanceVM } from "presentation/hook/Parameter/useParameterMaintenanceVM";
import ParameterDetailTablePanel from "./Table/ParameterDetailTablePanel";
import { ParameterDetailPanel } from "./Form/ParameterDetailPanel";

export const ParameterEditPanel: React.FC = () => {
    const [paramMainState] = useParameterMaintenanceTracked();
    const paramMainVM = useParameterMaintenanceVM();
    const { isSliderOpen } = paramMainState.detailState;
    const parameterEty = paramMainState.currentSelectedRow;

    useEffect(() => {
        parameterEty?.parameterCode && paramMainVM.onSearchParamDtl(parameterEty.parameterCode)
    }, [paramMainVM, parameterEty.id, parameterEty.parameterCode])
    
        return <div className='main-comp-wrapper'>
                <SliderPanel
                    isOpen={isSliderOpen}
                    leftSectionWidth={'100%'}
                    rightSectionWidth={'60%'}
                    draggable={true}
                    leftChildren={<ParameterDetailTablePanel/>}
                    rightChildren={<ParameterDetailPanel/>} />
            </div>
    }
    
export default memo(ParameterEditPanel);