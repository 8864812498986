import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { useParameterMaintenanceVM } from "presentation/hook/Parameter/useParameterMaintenanceVM";
import ParameterTitleBar from "./ParameterTitleBar";
import ParameterTablePanel from "./Table/ParameterTablePanel";

const ParameterMaintenance:React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    //const [paramMainState] = useParameterMaintenanceTracked();
    const paramMainVM = useParameterMaintenanceVM();
    //const {searchCriteria,isShowCriteriaPanel,isBackFromDetail} = paramMainState;
    

    useEffect(() => {
        const initialScreen = async() => {   
            setIsLoading(true);
            try {     
                const results = await Promise.allSettled([
                    paramMainVM.loadDropdownOption(),
                    paramMainVM.searchParameter()
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    } 
                })
            } catch(error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(async () => {
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
        
    }, [paramMainVM])


    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <>
        <div className={`main-comp-wrapper${false ? '' : ' im-hide-side-form-draggable'}`}>
           <ParameterTitleBar/>
            <SliderPanel
                isOpen={false}
                draggable={false}
                leftSectionWidth={"0%"}
                rightSectionWidth={"100%"}
                leftChildren={<ParameterTablePanel/>}                
                />
        </div>
    </>
}

export default memo(ParameterMaintenance);